@font-face {
    font-family: "OpenSans";
    src:
        url("opensans/OpenSans.woff2") format("woff2"),
        url("opensans/OpenSans.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "OpenSans-Medium";
    src:
        url("opensans/OpenSans-Medium.woff2") format("woff2"),
        url("opensans/OpenSans-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "OpenSans-SemiBold";
    src:
        url("opensans/OpenSans-SemiBold.woff2") format("woff2"),
        url("opensans/OpenSans-SemiBold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "OpenSans-Light";
    src:
        url("opensans/OpenSans-Light.woff2") format("woff2"),
        url("opensans/OpenSans-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "OpenSans-Bold";
    src:
        url("opensans/OpenSans-Bold.woff2") format("woff2"),
        url("opensans/OpenSans-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
