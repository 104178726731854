@import "./fonts/fonts";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
:root {
  --brand-1: #db4d42 !important;
  --brand-2: #00ebff !important;
  --brand-2-200: #00d8eb !important;
  --brand-2-300: #00c5d6 !important;
}
html {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard syntax */
  margin: 0;
  padding: 0;
}
.App {
  text-align: center;
  position: relative;
  font-family: var(--font-body);
  color: var(--black);
  &.blur {
    filter: blur(24px) saturate(0);
    opacity: 0.3;
  }
}
.ToastRoot p {
  font-size: 16px !important;
  z-index: 9999;
}
.btnGroup {
  position: fixed;
  z-index: 2;
  right: 24px;
  top: 24px;
}
.text-large {
  font-size: 24px;
  line-height: 1;
}
.IconWrapper {
  width: 50%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.logoWrapper {
  position: fixed;
  z-index: 2;
  left: 24px;
  top: 22px;
}
.RandomIcon {
  position: fixed;
  z-index: 1;
  inset: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.link {
  font-size: 16px;
  line-height: 1;
  padding: 0;
  cursor: pointer;
  border-bottom: 1px solid currentColor;
  // position: absolute;
  // right: auto;
  // transform: rotate(90deg) translateY(-50%);
  // transform-origin: center;
  pointer-events: auto;
  z-index: 99;
  // left: calc(100% + 24px);
  width: fit-content;
  display: block;
  text-wrap: nowrap;
}
.DropdownMenuContent {
  z-index: 9999;
  border-radius: var(--size-100);
}
.colorBox {
  width: 44px;
  min-width: 44px;
  height: 44px;
  min-height: 44px;
  display: flex;
  border-radius: var(--size-100);
  cursor: pointer;
  border: 1px solid var(--border-neutral-subtle);
}
.DialogOverlay:not(.DialogOverlayRequest) {
  opacity: 0;
}
.DialogContent:not(.DialogContentRequest) {
  // overflow: hidden;
  padding: 0;
  max-width: 100%;
  padding-left: 10%;
  padding-right: 10%;
  max-height: 100%;
  height: calc(100vh);
  display: flex;
  background-color: transparent;
  box-shadow: none;
  border: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  z-index: 100;
  border-radius: 0;
  padding-top: var(--size-400);
  .Group {
    padding: 0 var(--size-100);
  }
  .closeButton {
    background-color: var(--white);
    border-radius: 50%;
    overflow: hidden;
    top: var(--size-400);
    right: 24px;
  }
  .inputfield {
    max-width: 400px;
  }
  .iconGrid {
    padding: 0 var(--size-100);
    box-sizing: border-box;
  }
  .ScrollAreaRoot {
    box-sizing: border-box;
    .ScrollAreaViewport {
      max-height: 100%;
      padding-bottom: var(--size-400);
    }
    .ScrollAreaScrollbar {
      margin-bottom: var(--size-400);
    }
  }
}
.iconItem {
  border-radius: var(--size-150);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  flex: 1;
  gap: var(--size-200);
  position: relative;
  overflow: hidden;
  transform-origin: center;
  animation: scale 200ms ease forwards 0s;
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer;
  border: 2px solid var(--white);
  &:hover {
    border: 2px solid;
    .copy {
      opacity: 1;
    }
  }
  .copy {
    position: absolute;
    opacity: 0;
    top: 8px;
    right: 8px;
    stroke-width: 1.25px;
  }
  @keyframes scale {
    0% {
      transform: scale(0.6);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  // &:after {
  //   position: absolute;
  //   inset: 0;
  //   background-color: var(--white);
  //   content: "";
  //   display: block;
  //   z-index: -1;
  //   border-radius: var(--size-150);
  //   opacity: 0.9;
  //   filter: blur(16px);
  // }

  min-width: 148px;
  box-sizing: border-box;
  padding: var(--size-200);
  p {
    font-size: 10px;
  }
}
.mobile {
  display: none;
}
@media screen and (max-width: 735px) {
  .IconWrapper {
    width: 80%;
    margin-top: var(--size-1200);
  }
  .logoWrapper {
    right: 24px;
  }
  .nomobile {
    display: none;
  }
  .mobile {
    display: block;
  }
  .text-large,
  .link {
    font-size: 16px;
  }
  .btnGroup {
    flex-direction: column-reverse;
    bottom: 32px;
    top: auto;
    right: 44px;
    left: 44px;
    margin: auto;
    button {
      width: 100%;
    }
  }
  .RandomIcon {
    align-items: start;
  }
  .DialogContent {
    max-width: 100%;
    padding-left: var(--size-200);
    padding-right: var(--size-200);
    padding-top: var(--size-600);
    height: calc(100vh);
    max-width: 100%;
    width: 100%;
    .closeButton {
      top: 8px;
      right: 8px;
    }
    .Group {
      flex-direction: column;
      align-items: center;
      .inputfield {
        max-width: 90%;
      }
      .colorPicker {
        width: 90%;
        .colorBox {
          flex: 1;
          max-width: 50%;
        }
      }
    }

    .inputfield {
      margin: 0 var(--size-100);
    }
  }
}
.DialogContentRequest {
  padding: var(--size-500) var(--size-600) var(--size-500) var(--size-600);
}
.lottie {
  position: fixed;
  top: 50%;
  bottom: 0;
  transform: translateY(-50%);
  z-index: 999;
  height: 100vh;
  width: auto;
}
.Request {
  h3,
  h4,
  p {
    text-align: center;
  }
  &Slider {
    h3:first-child {
      flex: 1;
      text-wrap: nowrap;
    }
  }
  form {
    width: 100%;
    display: flex;
    gap: var(--size-200);
    flex-direction: column;
  }
}
